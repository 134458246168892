<template>
	<div>
		<CRow>
			<CCol lg="12">
				<h4 class="mb-4">
					{{ title }}
				</h4>
			</CCol>
		</CRow>

		<CRow class="mb-5 align-items-baseline">
			<CCol md="4">
				<BaseInputDate
					v-model="$v.startDateValue.$model"
					:is-valid="!$v.startDateValue.$error"
					:label="labelStartDate"
					invalid-feedback="Start date is required"
					placeholder="Select date"
					data-test-id="input-start-date"
					@input="handleActiveStartDateChange"
				>
					<template #append-content>
						<CIcon class="cil-calendar" name="cil-calendar" />
					</template>
				</BaseInputDate>
			</CCol>
			<CCol md="2">
				<BaseInputTime
					v-model="$v.startTimeValue.$model"
					:label="labelStartTime"
					:is-valid="!$v.startTimeValue.$error"
					:invalid-feedback="errorStartTime"
					data-test-id="input-start-time"
				/>
			</CCol>
			<CCol md="4">
				<BaseInputDate
					v-model="endDateValue"
					:disabled="!$v.startDateValue.$model || $v.startDateValue.$invalid"
					:min-date="minDate"
					label="End date"
					placeholder="Select date"
					data-test-id="input-end-date"
				>
					<template #append-content>
						<CIcon class="cil-calendar" name="cil-calendar" />
					</template>
				</BaseInputDate>
			</CCol>
			<CCol md="2">
				<BaseInputTime
					v-model="$v.endTimeValue.$model"
					:disabled="!$v.startDateValue.$model || $v.startDateValue.$invalid || !endDateValue"
					:is-valid="!$v.endTimeValue.$error"
					:invalid-feedback="errorEndTime"
					label="End time"
					data-test-id="input-end-time"
				/>
			</CCol>
		</CRow>
	</div>
</template>

<script>
import BaseInputDate from '@/components/BaseInputDate.vue';
import BaseInputTime from '@/components/BaseInputTime.vue';
import dayjs from 'dayjs';
import isEmpty from 'lodash/isEmpty';
import { requiredIf } from 'vuelidate/lib/validators';
import { convertDateTimeToUTC } from '../assets/js/helpers';

export default {
	name: 'ActiveSchedule',

	components: {
		BaseInputDate,
		BaseInputTime,
	},

	validations() {
		return {
			startDateValue: {
				required: requiredIf((data) => data.isRequire),
			},
			startTimeValue: {
				required: requiredIf((data) => data.isRequire),
				validTime(time) {
					return time ? time.length === 5 || time.length === 0 : true;
				},
			},
			endTimeValue: {
				validTime(time) {
					return time ? time.length === 5 || time.length === 0 : true;
				},
				after(time) {
					const startAt = convertDateTimeToUTC(this.startDateValue, this.startTimeValue ?? '00:00');
					const endAt = convertDateTimeToUTC(this.endDateValue, time);
					if (this.endDateValue) {
						if (dayjs(endAt).isAfter(dayjs(startAt))) {
							return true;
						}
						return false;
					}
					return true;
				},
			},
		};
	},

	props: {
		title: {
			type: String,
			default: 'Active Schedule',
		},
		isRequire: {
			type: Boolean,
			default: false,
		},
		startDate: {
			type: [Date, String],
			default: null,
		},
		startTime: {
			type: [String, Number],
			default: null,
		},
		endDate: {
			type: [Date, String],
			default: null,
		},
		endTime: {
			type: [String, Number],
			default: null,
		},
	},

	computed: {
		startDateValue: {
			get() {
				return this.startDate;
			},
			set(value) {
				this.$emit('update:startDate', value);
			},
		},

		startTimeValue: {
			get() {
				return this.startTime;
			},
			set(value) {
				this.$emit('update:startTime', value);
			},
		},

		endDateValue: {
			get() {
				return this.endDate;
			},
			set(value) {
				this.$emit('update:endDate', value);
			},
		},

		endTimeValue: {
			get() {
				return this.endTime;
			},
			set(value) {
				this.$emit('update:endTime', value);
			},
		},

		labelStartDate() {
			return this.isRequire ? 'Start date*' : 'Start date';
		},

		labelStartTime() {
			return this.isRequire ? 'Start time*' : 'Start time';
		},

		errorStartTime() {
			if (this.startDateValue && this.startTimeValue) {
				if (!this.startTimeValue.required) {
					return 'Required field';
				}
				if (!this.startTimeValue.validTime) {
					return 'Time not valid';
				}
			}
			return null;
		},

		errorEndTime() {
			if (this.endDateValue && this.endTimeValue) {
				if (!this.endTimeValue.validTime) {
					return 'Time not valid';
				}

				if (!this.endTimeValue.after) {
					return `Should be after ${this.startTimeValue}`;
				}
			}
			return null;
		},
		minDate() {
			return new Date(this.startDateValue);
		},
	},

	methods: {
		handleActiveStartDateChange(value) {
			if (isEmpty(value)) {
				//* case don't input start date
				this.endDateValue = null;
			}
			if (
				isEmpty(this.endDateValue) ||
				dayjs(value).isAfter(dayjs(this.endDateValue))
			) {
				this.endDateValue = value;
			}
		},
	},
};
</script>
