<template>
	<div class="main-wrapper">
		<!-- Head -->
		<CRow>
			<CCol
				col="12"
				class="d-flex justify-content-between align-items-center mb-4"
			>
				<h4>
					General info
				</h4>
				<span class="title-caption typo-caption color-black-45">
					ID: <span class="typo-caption color-black-45">{{ formData.id || 'Generate after creates' }}</span>
				</span>
			</CCol>
			<CCol col="12">
				<CInput
					v-model="$v.formData.name.$model"
					:is-valid="!$v.formData.name.$error && null"
					:invalid-feedback="$t('global.error.required')"
					label="Name*"
				/>
			</CCol>
		</CRow>

		<!-- Status -->
		<CRow class="mb-5">
			<CCol xl="12" class="d-flex align-items-center">
				<label class="toggle-label" for="display-status">Status</label>
				<CSwitch
					id="display-status"
					:checked="formData.isActive"
					variant="3d"
					size="sm"
					class="switch-custom toggle-status"
					color="success"
					@update:checked="handleUpdateStatus"
				/>
				<span>{{ statusText }}</span>
			</CCol>
		</CRow>

		<hr class="my-4">

		<!-- Date -->
		<ActiveSchedule
			ref="date"
			:start-date="formData.startDate"
			:start-time="formData.startTime"
			:end-date="formData.endDate"
			:end-time="formData.endTime"
			:is-require="false"
			@update:startDate="formData.startDate = $event"
			@update:startTime="formData.startTime = $event"
			@update:endDate="formData.endDate = $event"
			@update:endTime="formData.endTime = $event"
		/>

		<!-- Image -->
		<CRow class="mb-5">
			<CCol
				col="12"
				class="d-flex justify-content-between align-items-center mb-4"
			>
				<h4>
					Promotion image
				</h4>
			</CCol>
			<CCol col="12">
				<BaseMediaSelectorBox
					ref="bannerDesktop"
					v-model="formData.desktopBanner"
					title="Desktop*"
					no-image-text="No banner"
					show-helper
					width="700"
					height="350"
					class="mt-2"
					required
				/>
			</CCol>

			<CCol col="12">
				<BaseMediaSelectorBox
					ref="bannerMobile"
					v-model="formData.mobileBanner"
					title="Mobile*"
					no-image-text="No banner"
					show-helper
					width="700"
					height="350"
					class="mt-2"
					required
				/>
			</CCol>

			<CCol md="12">
				<CInput
					v-model.trim="formData.link"
					:is-valid="!$v.formData.link.$error"
					class="margin-bottom-0-important"
					data-id="product-link"
					label="Compare Link"
					:invalid-feedback="linkErrorMessage"
				/>
			</CCol>
		</CRow>

		<!-- Footer -->
		<BaseActionPanelStickyFooter
			:disabled-confirm="isSubmitting"
			:disabled-cancel="isSubmitting"
			:disabled-remove="isSubmitting"
			:is-edit="isEditMode"
			:remove-text="canRemove"
			@onCancel="handleCancel"
			@onConfirm="handleConfirm"
			@onRemove="handleRemove"
		/>
		<BaseModalConfirmDelete
			ref="modal-remove"
			:handle-remove="deletePromotionBannerKey.bind(null, $route.params.id)"
			title="Remove this promotion banner"
			description="Are you sure you want to delete this promotion banner?"
			@onSuccess="$router.push({ name: ROUTE_NAME.PROMOTION_BANNER_KEY_LIST })"
		/>
	</div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

import BaseMediaSelectorBox from '@/components/BaseMediaSelectorBox.vue';
import ActiveSchedule from '@/components/ActiveSchedule.vue';

import { required, requiredIf, url } from 'vuelidate/lib/validators';
import { scrollToTop, convertDateTimeToUTC } from '../assets/js/helpers';
import { ROUTE_NAME } from '../enums/route';
import { STATUS as mappingStatus } from '../mapping/banner';

export default {
	name: 'PromotionBannerKeysForm',
	components: {
		BaseMediaSelectorBox,
		ActiveSchedule,
	},

	validations: {
		formData: {
			name: {
				required,
			},
			link: {
				required: requiredIf((data) => data.link),
				url,
			},
		},
	},
	props: {
		isEditMode: {
			type: Boolean,
			default: false,
		},
		defaultData: {
			type: Object,
			default: null,
		},
	},

	data() {
		const {
			id,
			name = '',
			desktopBanner = null,
			mobileBanner = null,
			link = null,
			isActive = false,
			startDate = null,
			startTime = '00:00',
			endDate = null,
			endTime = '23:59',
		} = this.defaultData || {};
		return {
			formData: {
				id,
				name,
				desktopBanner,
				mobileBanner,
				link,
				isActive,
				startDate,
				startTime,
				endDate,
				endTime,
			},
			isSubmitting: false,
			ROUTE_NAME,
		};
	},

	computed: {
		...mapGetters({
			isCreateSuccess: 'promotionBannerKeys/isCreateSuccess',
		}),

		canRemove() {
			return this.isEditMode ? 'Remove Promotion Banner Key' : '';
		},
		linkErrorMessage() {
			if (!this.$v.formData.link.url) {
				return this.$t('page.banner.create.linkBannerInvalidFormat');
			} else if (!this.$v.formData.link.required) {
				return this.$t('global.error.required');
			}
			return null;
		},
		statusText() {
			const text = mappingStatus[this.formData.isActive] || '';
			return this.$t(text);
		},
	},

	methods: {
		...mapActions({
			createPromotionBannerKey: 'promotionBannerKeys/createPromotionBannerKey',
			updatePromotionBannerKey: 'promotionBannerKeys/updatePromotionBannerKey',
			deletePromotionBannerKey: 'promotionBannerKeys/deletePromotionBannerKey',
		}),

		test() {
			this.isSubmitting = true;
		},

		async handleConfirm() {
			const dateRef = this.$refs.date.$v;
			const desktopBannerRef = this.$refs.bannerDesktop.$v;
			const mobileBannerRef = this.$refs.bannerMobile.$v;

			this.$v.$touch();
			dateRef.$touch();
			desktopBannerRef.$touch();
			mobileBannerRef.$touch();

			const isInvalid = this.$v.$invalid || dateRef.$invalid || desktopBannerRef.$invalid || mobileBannerRef.$invalid;
			if (isInvalid) {
				scrollToTop();
				return;
			}

			this.isSubmitting = true;

			let startDateTime = null;
			if (this.formData.startDate) {
				startDateTime = convertDateTimeToUTC(this.formData.startDate, this.formData.startTime);
			}
			let endDateTime = null;
			if (this.formData.startDate && this.formData.endDate) {
				endDateTime = convertDateTimeToUTC(this.formData.endDate, this.formData.endTime);
			}

			const form = {
				name: this.formData.name,
				media: {
					desktop_file_id: this.formData.desktopBanner.id,
					mobile_file_id: this.formData.mobileBanner.id,
				},
				link: this.formData.link,
				is_active: this.formData.isActive,
				start_at: startDateTime,
				end_at: endDateTime,
			};

			if (!this.isEditMode) {
				await this.createPromotionBannerKey(form);
				if (this.isCreateSuccess) {
					this.$router.push({ name: ROUTE_NAME.PROMOTION_BANNER_KEY_LIST });
				}
				this.isSubmitting = false;
				return;
			}
			await this.updatePromotionBannerKey({ id: this.$route.params.id, params: form });
			this.isSubmitting = false;
		},
		handleRemove() {
			this.$refs['modal-remove'].open();
		},
		handleCancel() {
			this.$router.push({ name: ROUTE_NAME.PROMOTION_BANNER_KEY_LIST });
		},
		handleUpdateStatus(value) {
			this.formData.isActive = value;
		},
	},
};
</script>

<style lang="scss" scoped>
	.toggle {
		&-label {
			width: rem(54);
			margin: 0 rem(24) 0 0;
		}

		&-status {
			margin-right: rem(12);
		}
	}
</style>
